import { useForm } from 'vee-validate'
import { skipHydrate } from 'pinia'
import { brandSchema } from 'gig/validation-schema/create/gallery'
import type { GigResponse } from 'gig/types/create-gig'
import type { Creator } from '@/types/creator'
import type { UploadAssetsResponse } from '@/types/asset'

export const useBrandUploadStore = defineStore('gigBrandUploadStore', () => {
  const createGigStore = useGigCreateStore()
  const pricingStore = usePricingStore()
  const galleryStore = useGalleryStore()
  const creatorStore = useCreatorStore()
  const { $notify } = useNuxtApp()

  const isProcessing = ref(false)
  const form = useForm({ validationSchema: brandSchema })
  const [image, name] = form.useFieldModel(['image', 'name'])
  const { isSubmitted: isFormSubmitted } = useValidation(form.submitCount)
  const selectedImage = ref<{ url?: string; brand?: string }>({})
  const brands = ref()

  const uploadBrand = async () => {
    try {
      isProcessing.value = true
      const formData = new FormData()
      formData.append('logo', image.value)
      formData.append('brand_name', name.value)

      const { data, error } = await useApi<Creator>(`/brand`, {
        method: 'post',
        isUploadForm: true,
        body: formData,
      })

      if (error.value) {
        throw new Error(error.value.message)
      }

      creatorStore.creator = data.value

      const newBrandLogo = creatorStore.brandLogos.find(
        (asset) => asset.name['en-US'] === name.value,
      )

      await updateBrandsInGig(newBrandLogo?.id, 'add')

      $notify('Uploaded media')

      await creatorStore.fetchCreatorData()

      return true
    } catch (error) {
      $notify({
        text: (error as Error).message || 'Failed to upload',
        severity: 'error',
      })

      console.error((error as Error).message)
    } finally {
      isProcessing.value = false
    }
  }

  const removeBrand = async (id: string) => {
    try {
      if (!creatorStore.creator) {
        return
      }

      const { gigs } = await useGigList(
        `masterData(current(masterVariant(attributes(name = "brand_assets" and value in ("${id}")))))`,
      )
      const isBrandUsedInOneGig = gigs.value.length < 2

      if (isBrandUsedInOneGig) {
        const isBrandRemovedFromCreator = await creatorStore.updateProfile(
          [
            {
              action: 'removeAsset',
              staged: false,
              variantId:
                creatorStore.creator.masterData.current.masterVariant.id,
              assetId: id,
            },
          ],
          { prefetchData: true },
        )
        if (!isBrandRemovedFromCreator) {
          return false
        }
      }

      await updateBrandsInGig(id, 'remove')

      galleryStore.brandIds = galleryStore.brandIds.filter(
        (brandId: string) => brandId !== id,
      )

      $notify('Successfully removed media')

      await galleryStore.fetchGigAssets()

      return true
    } catch (error) {
      $notify({
        text: 'Failed to remove media',
        severity: 'error',
      })

      console.error((error as Error).message)
    }
  }

  const updateBrandsInGig = async (
    id: string | undefined,
    type: 'add' | 'remove',
  ) => {
    try {
      if (!id) {
        return
      }

      await galleryStore.fetchGigAssets()
      const brandIds = galleryStore.brandIds || []

      if (type === 'add') {
        if (brandIds.includes(id)) {
          return
        } else {
          brandIds.push(id)
        }
      }

      if (type === 'remove') {
        const index = brandIds.indexOf(id)

        if (index > -1) {
          brandIds.splice(index, 1)
        }
      }

      const body = {
        version: createGigStore.gigVersion as number,
        actions: [
          {
            action: 'setAttributeInAllVariants',
            staged: false,
            name: 'brand_assets',
            value: brandIds,
          },
        ],
      }

      const { data, error } = await useApi<GigResponse>(
        `me/product/${createGigStore.gigId}`,
        {
          method: 'post',
          body,
        },
      )

      if (data.value) {
        createGigStore.gigVersion = data.value.version
        pricingStore.copyAdditionalAttributesFromResponse(data.value)
      }

      if (error.value) {
        throw new Error(error.value.message)
      }
    } catch (error) {
      console.error((error as Error).message)
    }
  }

  const fetchBrands = async () => {
    try {
      const runtimeConfig = useRuntimeConfig()
      const { data } = await useApi<UploadAssetsResponse>('brand', {
        method: 'get',
        baseURL: runtimeConfig.public.uploadUrl,
      })
      const creatorStore = useCreatorStore()
      await creatorStore.fetchCreatorData()
      const creatorLogos = creatorStore.brandLogos?.map((logo) => {
        return {
          brand: logo.name['en-US'],
          url: logo.sources?.[0]?.uri,
          contentType: logo.sources?.[0]?.contentType,
          dimensions: logo.sources?.[0]?.dimensions,
        }
      })

      brands.value = [
        {
          label: 'Previously uploaded',
          code: 'uploaded',
          items: creatorLogos,
        },
        {
          label: 'Popular',
          code: 'popular',
          items: data.value,
        },
      ]
    } catch (error) {
      brands.value = []
    }
  }

  return {
    isProcessing: skipHydrate(isProcessing),
    isFormSubmitted,
    form,
    image,
    selectedImage,
    name,
    uploadBrand,
    removeBrand,
    fetchBrands,
    brands,
  }
})
