import { skipHydrate } from 'pinia'
import { useForm } from 'vee-validate'
import { assetSchema } from 'gig/validation-schema/create/gallery'
import type { UpdateGigData, GigResponse } from 'gig/types/create-gig'
import type { UploadAssetsResponse } from '@/types/asset'

export const useAssetUploadStore = defineStore('gigAssetUploadStore', () => {
  const createGigStore = useGigCreateStore()
  const pricingStore = usePricingStore()
  const galleryStore = useGalleryStore()
  const uploadAssetStore = useAssetUploadStore()
  const { $notify } = useNuxtApp()

  const isProcessing = ref(false)
  const thumbnail = ref<string | undefined>()
  const id = ref<string | undefined>()

  const form = useForm({ validationSchema: assetSchema })
  const [asset, assetAlt] = form.useFieldModel(['asset', 'assetAlt'])
  const { isSubmitted: isFormSubmitted } = useValidation(form.submitCount)

  const uploadAsset = async (
    options: { notify: boolean } = { notify: true },
  ) => {
    try {
      isProcessing.value = true
      const formData = new FormData()
      formData.append('asset', asset.value)
      formData.append('label', assetAlt.value || 'Collab asset')
      formData.append('staged', 'false')

      const { error } = await useApi<UploadAssetsResponse>(
        `/gig/${createGigStore.gigId}`,
        {
          method: 'post',
          isUploadForm: true,
          body: formData,
        },
      )

      if (error.value) {
        throw new Error(error.value.message)
      }

      if (options.notify) {
        $notify('Uploaded media')
      }

      await galleryStore.fetchGigAssets()
      return true
    } catch (error) {
      const networkErrorMessages = [
        'NetworkError when attempting to fetch resource. ()',
        'Failed to fetch ()',
        'Load failed ()',
      ]
      const errorMessage = (error as Error).message
      const additionalMessage = networkErrorMessages.includes(errorMessage)
        ? ' Please check your Internet connection.'
        : ''

      if (options.notify) {
        $notify({
          text: 'Failed to upload.' + additionalMessage,
          severity: 'error',
        })
      }

      console.error(errorMessage)
    } finally {
      isProcessing.value = false
    }
  }

  const changeAssetOrder = async (assetOrder: string[]) => {
    try {
      const body: UpdateGigData = {
        version: createGigStore.gigVersion as number,
        actions: [
          {
            action: 'changeAssetOrder',
            staged: false,
            variantId: pricingStore.masterVariantId as number,
            assetOrder,
          },
        ],
      }

      const { data, error } = await useApi<GigResponse>(
        `me/product/${createGigStore.gigId}`,
        {
          method: 'post',
          body,
        },
      )
      const gig = data.value

      if (error.value || !gig) {
        throw new Error(error.value?.message || 'No Collab data')
      }

      createGigStore.gigVersion = gig.version
      galleryStore.assets = gig.masterData.current.masterVariant.assets
      galleryStore.originalAssetsId = galleryStore.assets.map(
        (asset) => asset.id,
      )
    } catch (error) {
      console.error((error as Error).message)
    }
  }

  const updateAsset = async () => {
    /*
    General explanation:
    There's no endpoint for updating asset. Instead:
    1. Remove old asset
    2. Upload new asset
    3. Change assets order so that new asset is in the same position as the old asset
    */

    try {
      if (!id.value) {
        throw new Error('No asset id')
      }

      isProcessing.value = true

      let assetIdList = galleryStore.originalAssetsId
      const isSuccess = await uploadAssetStore.removeAsset(id.value, {
        notify: false,
      })
      if (isSuccess) {
        const oldAssetIndex = assetIdList.indexOf(id.value)

        return await uploadAsset({ notify: false }).then(async () => {
          assetIdList = galleryStore.assets.map((asset) => asset.id)
          const newAssetIndex = assetIdList.length - 1
          const newAssetId = assetIdList[newAssetIndex]
          assetIdList = [
            ...assetIdList.slice(0, oldAssetIndex),
            newAssetId,
            ...assetIdList.slice(oldAssetIndex, newAssetIndex),
          ]

          return await changeAssetOrder(assetIdList).then(() => {
            $notify('Updated media')
            return true
          })
        })
      }
    } catch (error) {
      $notify({
        severity: 'error',
        text: 'Failed to update media',
      })

      return false
    } finally {
      isProcessing.value = false
    }
  }

  const removeAsset = async (
    id: string,
    options: { notify: boolean } = { notify: true },
  ) => {
    try {
      const body: UpdateGigData = {
        version: createGigStore.gigVersion as number,
        actions: [
          {
            action: 'removeAsset',
            staged: false,
            variantId: pricingStore.masterVariantId as number,
            assetId: id,
          },
        ],
      }

      const { data, error } = await useApi<GigResponse>(
        `me/product/${createGigStore.gigId}`,
        {
          method: 'post',
          body,
        },
      )

      if (error.value) {
        throw new Error(error.value.message)
      }

      createGigStore.gigVersion = data.value?.version
      galleryStore.assets = galleryStore.assets.filter(
        (asset: { id: string }) => asset.id !== id,
      )
      if (options.notify) {
        $notify('Successfully removed media')
      }

      return true
    } catch (error) {
      if (options.notify) {
        $notify({
          text: 'Failed to remove media',
          severity: 'error',
        })
      }

      await galleryStore.fetchGigAssets()
      console.error((error as Error).message)
    }
  }

  return {
    isProcessing: skipHydrate(isProcessing),
    thumbnail: skipHydrate(thumbnail),
    id: skipHydrate(id),
    form,
    asset,
    assetAlt,
    isFormSubmitted,
    uploadAsset,
    updateAsset,
    changeAssetOrder,
    removeAsset,
  }
})
